import Box from '@mui/material/Box';
import React, { useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { highlightPDF } from '../../../../api/regulatory_information';
import useKeyDocuments from '../../hooks/useKeyDocuments';
import PDFViewer from '../../../PDFViewer';

import styles from '../../styles/KeyDocument';

const KeyDocuments: React.FC<any> = ({
  applno,
  submissionType,
  submissionNumber,
  pdfMetaData,
  center,
  blobUrl,
  handleBlobUrl,
  onClose
}: any) => {
  const { keyDocuments, getKeyDocuments } = useKeyDocuments(
    applno,
    submissionType,
    submissionNumber,
    center
  );
  const [selectedDocumentCategory, setDocumentCategory] = useState<any>();
  const [selectedDocument, setDocument] = useState<any>();
  const [anchorEl, setAnchorElement] = useState<any>();

  useEffect(() => {
    setDocument(null);
    getKeyDocuments();
  }, [applno, submissionType, submissionNumber]);

  useEffect(() => {
      const category = pdfMetaData.category || 'Letters'
      setDocumentCategory(category);
      if (keyDocuments[category]?.length > 0 && pdfMetaData?.documentId) {
          let documentWithDocumentId = keyDocuments[category].filter((document: any) => document.document_id === pdfMetaData.documentId);
          setDocument(documentWithDocumentId?.[0]);
        }         
      else {
        setDocument(keyDocuments[category]?.[0]);
      }
    }, [keyDocuments, pdfMetaData]);

  const onMenuItemSelect = useCallback((doc: any) => {
    setDocument(doc);
    setAnchorElement(null);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorElement(null);
  }, []);

  return (
    <Box height='100%' maxWidth={'45vw'}>
      {Object.keys(keyDocuments).map((category: any) => (
        <Button
          key={category}
          sx={selectedDocumentCategory === category ? styles.button__disabled : styles.button}
          onClick={(e: any) => {
            setDocumentCategory(category);
            setAnchorElement(e.currentTarget);
          }}>
          {category}
        </Button>
      ))}
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleMenuClose}>
        {keyDocuments[selectedDocumentCategory]?.map(
          (doc: { _category: string; s3_path: string; document_id: string }) => (
            <MenuItem
              key={doc.s3_path}
              // eslint-disable-next-line no-underscore-dangle
              selected={doc._category === selectedDocument?._category}
              onClick={() => {onMenuItemSelect(doc);}}>
              {
                // eslint-disable-next-line no-underscore-dangle
                doc.document_id
              }
            </MenuItem>
          )
        )}
      </Menu>
      <CancelIcon onClick={onClose} sx={styles.closeBtn} />
      <Box height='90%'>
          {
            selectedDocument && (
                <PDFViewer
                  fileUrl={selectedDocument.s3_path}
                  initialPage={pdfMetaData?.intialPageNo}
                  defaultScale={1}
                />
              )
          }
      </Box>
    </Box>
  );
};

export default React.memo(KeyDocuments);
