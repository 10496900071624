import React, { useEffect, useState } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { searchPlugin } from '@react-pdf-viewer/search';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/search/lib/styles/index.css';

interface PDFViewerProps {
  fileUrl: string;
  highlightTerms?: string[];
  initialPage?: number;
  defaultScale?: number;
}

const PDFViewer = ({
  fileUrl,
  highlightTerms = [],
  initialPage = 0,
  defaultScale = 1
}: PDFViewerProps) => {
  const [currentPage, setCurrentPage] = useState(initialPage);
  const defaultLayoutPluginInstance = defaultLayoutPlugin({ sidebarTabs: () => [] });
  const searchPluginInstance = searchPlugin({
    keyword: highlightTerms
  });

  useEffect(() => {
    setCurrentPage(initialPage);
  }, [initialPage]);

  return (
    <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.js'>
      <Viewer
        key={currentPage} // Forces re-render when currentPage changes
        fileUrl={fileUrl}
        initialPage={currentPage}
        defaultScale={defaultScale}
        plugins={[defaultLayoutPluginInstance, searchPluginInstance]}
      />
    </Worker>
  );
};

PDFViewer.defaultProps = {
  highlightTerms: [],
  initialPage: 0,
  defaultScale: 1
};

export default PDFViewer;
