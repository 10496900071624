import { useCallback, useState } from 'react';
import groupBy from 'lodash/groupBy';
import { getPDF } from '../../../api/regulatory_information';

const useKeyDocuments = (applno: string, submissionType: string, submissionNumber: number,center:string='CDER') => {
  const [isLoading, setLoading]: [boolean, Function] = useState(false);
  const [errorMessage, setErrorMessage]: [string, Function] = useState('');
  const [keyDocuments, setKeyDocuments]: [any, Function] = useState([]);

  const getKeyDocuments = useCallback(async () => {
    setLoading(true);
    setErrorMessage('');
    setKeyDocuments([]);
    try {
      const response: any = await getPDF(applno, submissionType, submissionNumber,center);
      if (response?.data?.Success) {
        let docs = response?.data?.Success;
        // eslint-disable-next-line no-underscore-dangle
        docs = docs.filter((doc:any)=>doc.category_bucket !== 'Labels').sort((a: any, b: any) => a._category.localeCompare(b._category));
        const groupedDocs = groupBy(docs, 'category_bucket');

        const sorted: any = {};

        const sortedKeys = Object.keys(groupedDocs).sort();
        sortedKeys.forEach((key: string) => {
          sorted[key] = groupedDocs[key];
        });

        setKeyDocuments(sorted);
      } else {
        setErrorMessage('Could not get the Key Documents');
      }
      setLoading(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setErrorMessage('Could not get the Key Documents');
    }
  }, [applno, submissionType, submissionNumber]);

  return {
    isLoading,
    errorMessage,
    keyDocuments,
    getKeyDocuments
  };
};

export default useKeyDocuments;
