import makeRequest, { makeArrayBufferRequest } from './client';

export const getKeyList = () => makeRequest(`/reginfo`, `GET`);

export const getKeyAttributes = (key: string) => makeRequest(`/reginfo/${key}`, `GET`);

export const addKeyAttributes = (payload: any) => makeRequest(`/reginfo/qc`, `POST`, payload);

export const updateKeyAttributes = (id: number, payload: any) =>
  makeRequest(`/reginfo/attribute/${id}`, `PUT`, payload);

export const getPDF = (applno: string, submissionType: string, submissionNumber: number,center:string) =>
  makeRequest(`/reginfo/${center}/${applno}/${submissionType}/${submissionNumber}/all`, `GET`);

export const highlightPDF = (payload: any) =>
  makeArrayBufferRequest('/pdf/highlight', `POST`, payload);
