import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';

// material
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import { AppBar, Toolbar } from '@mui/material';
import capitalize from 'lodash/capitalize';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { updatePMDAProductData } from '../../../api/pmda';
import Actions from '../../../store/actions';
import Store from '../../../store';
import ProductData from './ProductData';
import PriceListData from './PriceListData';
import ActiveIngredientData from './ActiveIngredientData';

export const GetField = ({ label, value, setLocalProductData }: any) => {
  if (
    label === 'parent_yj_code' ||
    label === 'is_sub_product' ||
    label === 'is_admin_check_required' ||
    label === 'other_trade_name' ||
    label === 'resource' ||
    label === 'qc_status' ||
    label === 'created_at' ||
    label === 'updated_at' ||
    label === 'product_type' ||
    label === 'curator_name' ||
    label === 'curator_email'
  ) {
    return null;
  }
  if (label === 'route') {
    return (
      <Autocomplete
        id='route'
        fullWidth
        value={value}
        freeSolo
        options={['Internal / Oral', 'Injectable', 'Topical / External Use', 'Dental', 'Other']}
        onChange={(event: ChangeEvent<{}>, newValue: string | null): void => {
          if (newValue !== null) {
            setLocalProductData((prev: any) => ({ ...prev, [label]: newValue }));
          }
        }}
        sx={{ mb: 2 }}
        renderInput={(
          params: any // eslint-disable-next-line react/jsx-props-no-spreading
        ) => <TextField {...params} label='Route' variant='outlined' required />}
      />
    );
  }
  if (label === 'formulation') {
    return (
      <Autocomplete
        id='formulation'
        fullWidth
        freeSolo
        value={value}
        options={['Powder', 'Tablet', 'Capsule', 'Liquid', 'Other']}
        onChange={(event: ChangeEvent<{}>, newValue: string | null): void => {
          if (newValue !== null) {
            setLocalProductData((prev: any) => ({ ...prev, [label]: newValue }));
          }
        }}
        sx={{ mb: 2 }}
        renderInput={(
          params: any // eslint-disable-next-line react/jsx-props-no-spreading
        ) => <TextField {...params} label='Formulation' variant='outlined' required />}
      />
    );
  }
  if (label === 'other_sponsor_name') {
    return (
      <>
        <TextField
          variant='outlined'
          fullWidth
          sx={{ mb: 2 }}
          id={`product-${label}-distributor`}
          label='Distributor'
          value={value?.Distributor}
          multiline
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setLocalProductData((prev: any) => ({
              ...prev,
              [label]: { ...prev[label], Distributor: e.target.value }
            }));
          }}
          InputProps={{
            style: { fontSize: '2vh' }
          }}
          InputLabelProps={{
            style: { fontSize: '2vh' }
          }}
        />
        <TextField
          variant='outlined'
          fullWidth
          id={`product-${label}-manufacturer`}
          label='Manufacturer'
          value={value?.Manufacturer}
          multiline
          sx={{ mb: 2 }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setLocalProductData((prev: any) => ({
              ...prev,
              [label]: { ...prev[label], Manufacturer: e.target.value }
            }));
          }}
          InputProps={{
            style: { fontSize: '2vh' }
          }}
          InputLabelProps={{
            style: { fontSize: '2vh' }
          }}
        />
        <TextField
          variant='outlined'
          fullWidth
          sx={{ mb: 2 }}
          id={`product-${label}-publisher`}
          label='Publisher'
          value={value?.Publisher}
          multiline
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setLocalProductData((prev: any) => ({
              ...prev,
              [label]: { ...prev[label], Publisher: e.target.value }
            }));
          }}
          InputProps={{
            style: { fontSize: '2vh' }
          }}
          InputLabelProps={{
            style: { fontSize: '2vh' }
          }}
        />
        <TextField
          variant='outlined'
          fullWidth
          id={`product-${label}-sales`}
          label='Sales'
          sx={{ mb: 2 }}
          value={value?.Sales}
          multiline
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setLocalProductData((prev: any) => ({
              ...prev,
              [label]: { ...prev[label], Sales: e.target.value }
            }));
          }}
          InputProps={{
            style: { fontSize: '2vh' }
          }}
          InputLabelProps={{
            style: { fontSize: '2vh' }
          }}
        />
      </>
    );
  }
  return (
    <TextField
      variant='outlined'
      fullWidth
      id={`product-${label}`}
      multiline={!label?.includes('date')}
      label={capitalize(label?.replaceAll('_', ' '))}
      value={value}
      type={label?.includes('date') ? 'date' : 'text'}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        setLocalProductData((prev: any) => ({ ...prev, [label]: e.target.value }));
      }}
      helperText={label?.includes('date') ? 'Date format is MM-DD-YYYY' : null}
      sx={{ mb: 2 }}
      InputProps={{
        style: { fontSize: '2vh' }
      }}
      InputLabelProps={{
        style: { fontSize: '2vh' }
      }}
    />
  );
};

const PMDAEditDialog = ({ closeEdit, productData }: any) => {
  const { dispatch } = useContext<any>(Store);
  const [localProductData, setLocalProductData] = useState<any>({});
  const [activeStep, setActiveStep] = useState<number>(0);

  const handleSubmit = async () => {
    try {
      const user = await Auth.currentUserInfo();
      const finalData: any = {};
      Object.entries(localProductData).forEach(([key, value]: any) => {
        if (key.includes('date')) {
          const date = new Date(value);
          let day: any = date.getDate();
          let month: any = date.getMonth() + 1;
          const year = date.getFullYear();
          // if month or day is less than 10, add 0 before it
          if (month < 10) {
            month = `0${month}`;
          }
          if (day < 10) {
            day = `0${day}`;
          }
          finalData[key] = `${year}-${month}-${day}`;
        } else {
          finalData[key] = value;
        }
      });

      const payload = {
        ...finalData,
        curator_name: user.attributes['custom:user'],
        curator_email: user.attributes.email,
        is_admin_check_required: finalData?.qc_status !== 'Qced'
      };
      const res = await updatePMDAProductData(payload, finalData?.yj_code);
      const resData = res.data;
      if (resData.error) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: resData.message }
        });
      } else {
        closeEdit();
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: resData.body, color: 'success' }
        });
      }
    } catch (e: any) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: e.message }
      });
    }
  };

  const handleNext = (num: number) => {
    setActiveStep(num);
  };

  const steps = [
    {
      label: 'Bookmark of Medicine',
      component: (
        <ActiveIngredientData
          productData={localProductData}
          setProductData={setLocalProductData}
          handleNext={() => handleNext(1)}
        />
      )
    },
    {
      label: 'Price List',
      component: (
        <PriceListData
          productData={localProductData}
          setProductData={setLocalProductData}
          handleNext={() => handleNext(2)}
        />
      )
    },
    {
      label: 'Product Data',
      component: (
        <ProductData
          productData={localProductData}
          setProductData={setLocalProductData}
          handleSubmit={handleSubmit}
          hideSubProductFields={false}
        />
      )
    }
  ];
  useEffect(() => {
    if (productData) {
      const { resource, ...rest } = productData;
      setLocalProductData(rest);
    }
  }, [productData]);
  return (
    <>
      <AppBar sx={{ position: 'relative', bgcolor: 'white.main' }}>
        <Toolbar>
          <IconButton edge='start' color='inherit' onClick={closeEdit} aria-label='close'>
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
            {localProductData?.trade_name} ({localProductData?.trade_name_jp})
          </Typography>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            sx={{
              width: '50%'
            }}>
            {steps.map(label => (
              <Step key={label.label}>
                <StepLabel>{label.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Toolbar>
      </AppBar>
      {steps[activeStep].component}
    </>
  );
};

export default React.memo(PMDAEditDialog);
