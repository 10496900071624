import makeRequest from './client';

const base = `/mhra/`;
export const getMHRALabelList = (
  page: number,
  perPage: number,
  status: string,
  applicationNumber: string,
  productNumber: string,
  date: string,
  original = false
) => {
  const queryParams = [`page=${page}`, `page_size=${perPage}`];
  if (status.toLowerCase() !== 'all') {
    queryParams.push(`qc_status=${status}`);
  }
  if (applicationNumber) {
    queryParams.push(`vin_number=${applicationNumber}`);
  }
  if (productNumber) {
    queryParams.push(`product_number=${productNumber}`);
  }
  queryParams.push(`section_separated=${original}`);
  if (date) {
    queryParams.push(`created_date=${date}`);
  }
  const finalURL = `${base}page-number?${queryParams.join('&')}`;
  return makeRequest(finalURL, 'GET');
};

export const fetchLabelDocs = (documentId: string, documentHash: string) => {
  return makeRequest(`${base}get-label/${documentId}?document_hash=${documentHash}`, 'GET');
};

export const submitLabelCurationDetails = (payload: any) => {
  return makeRequest(`${base}label_section`, 'POST', payload);
};

export const fetchLabelPageCurationDetails = (documentId: any, original: boolean) => {
  return makeRequest(
    `${base}page-number?pdf_metadata_id=${documentId}&section_separated=${original}`,
    'GET'
  );
};

export const submitLabelPageCuration = (payload: any) => {
  return makeRequest(`${base}page-number`, 'POST', payload);
};
