const APPROVAL_STATUS_METADATA: any = {
  accelerated: [
    {
      id: 'appl_title',
      label: 'Application Number'
    },
    {
      id: 'aa_indication',
      label: 'Indication Text'
    },
    {
      id: 'aa_date',
      label: 'Accerelated Date'
    },
    {
      id: 'conversion_withdrawal_status',
      label: 'Conversion Withdrawal Status'
    },
    {
      id: 'full_approval_conversion_withdrawal_date',
      label: 'Conversion Withdrawal Date'
    }
  ]
};

export const DEFAULT_HIGHLIGHT_TERMS: string[] = ['Accelerated'];

export default APPROVAL_STATUS_METADATA;
