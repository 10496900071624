import REGINFO from '../components/RegulatoryInformation/ApplicationTableView';
import REGINFODATA from '../components/RegulatoryInformation/ApplicationDetails';

const REG_INFO_ROUTES = [
  {
    id: 'regInfoKeyView',
    path: '/regulatoryinformation',
    component: REGINFO,
    exact: true
  },
  {
    id: 'regInfoDataView',
    path: '/regulatoryinformation/:center/:key',
    exact: true,
    component: REGINFODATA
  }
];
export default REG_INFO_ROUTES;