import SectionSeparationDashboard from '../components/MHRA/SectionSeparationDashboard';
import PageNumberCuration from '../components/MHRA/PageNumberCuration';

const MHRA_ROUTES = [
  {
    id: 'mhraPageNumberCuration',
    path: '/mhra/page',
    component: PageNumberCuration,
    exact: true
  },
  {
    id: 'mhraSectionSeperation',
    path: '/mhra/section',
    component: SectionSeparationDashboard,
    exact: true
  }
];
export default MHRA_ROUTES;
