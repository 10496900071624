import PMDA from '../components/PMDA';
import ProductResourcesCuration from '../components/PMDA/ProductResourcesCuration';
import AutoLabelTranslation from "../components/PMDA/AutoLabelTranslation";
import DAGCuration from "../components/PMDA/components/DAGCuration";
import EssentialDataCuration from "../components/PMDA/EssentialDataCuration";

const PMDA_BASE_ROUTE = '/pmda';
const PMDA_ESSENTIAL_DATA_CURATION_ROUTE = '/pmda/essential-data-curation';
const PMDA_PRODUCT_RESOURCES_SECTION_AND_NUMBER_CURATION_ROUTE = '/pmda/:curation_type';

const PMDA_ROUTES = [
  {
    id: 'pmda',
    path: PMDA_BASE_ROUTE,
    component: PMDA,
    exact: true
  },{
    id: 'pmdacuration',
    path: `${PMDA_BASE_ROUTE}/curation/check`,
    component: DAGCuration,
    exact: true
  },
  {
    id: 'pmda-package-insert-translation',
    path: `${PMDA_BASE_ROUTE}/translate/package-insert`,
    component: AutoLabelTranslation,
    exact: true
  },
  {
    id: 'pmdaProductResourcesSectionSeparation',
    path: PMDA_PRODUCT_RESOURCES_SECTION_AND_NUMBER_CURATION_ROUTE,
    component: ProductResourcesCuration,
    exact: false
  },
  {
    id: 'pmdaEssentialDataCuration',
    path: PMDA_ESSENTIAL_DATA_CURATION_ROUTE,
    component: EssentialDataCuration,
    exact: false
  }

];

export default PMDA_ROUTES;
