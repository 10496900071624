import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  Box,
  Button,
  CssBaseline,
  Dialog,
  LinearProgress,
  TextField,
  ThemeProvider,
  Typography
} from '@mui/material';
import { getEssentialDataCuration, updateEssentialDataCuration } from '../../api/pmda';
import Actions from '../../store/actions';
import Styles from './styles/ProductResourcesCuration.styles';
import Store from '../../store';
import { DataTableRow } from './types';
import themev5 from '../../themev5';
import styles from '../Datagrid/Datagrid.styles';
import DatagridFooter from '../Datagrid/DatagridFooter';
import DatagridNoRowsOverlay from '../Datagrid/DatagridNoRowsOverlay';
import ProductData from './components/ProductData';
import { Auth } from "aws-amplify";

const EssentialDataCuration = () => {
  const classes = Styles();
  const { dispatch } = useContext<any>(Store);
  const [dataTableRows, setDataTableRows] = useState<Array<DataTableRow>>([]);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [yjCode, setYjCode] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [pageNo, setPageNo] = useState(0);
  const [reload, setReload] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [rowData, setRowData] = useState<DataTableRow>();
  const [showEditDialog, setShowEditDialog] = useState(false);
  const rowsPerPageOptions = [10, 20, 50, 100];

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const result = await getEssentialDataCuration(yjCode, pageNo, pageSize);

        setIsLoading(false);
        if (!result?.data?.body?.result) {
          dispatch({
            type: Actions.SET_ALERT,
            value: { status: true, message: 'Error: Product not available' },
          });
        }
        setDataTableRows(result.data.body.result);
        setTotalRowCount(result.data.body.total);
      } catch (error) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: (error as Error).message }
        });
      }
    };

    getData();
  }, [pageNo, pageSize, reload]);

  const debouncedChangeHandler = () => {
    setReload(prevState => !prevState);
  };

  const handleChange = debounce(debouncedChangeHandler, 300);

  const handleYjCode = (value: string) => {
    setYjCode(value);
    handleChange();
  };
  const openEditDialog = (data: DataTableRow) => {
    setRowData(data);
    setShowEditDialog(true);
  };
  const closeEditDialog = () => {
    setShowEditDialog(false);
    setReload(prevState => !prevState);
  };

  const searchFields = [{ id: 'yj_code', label: 'YJ Code', value: yjCode, onChange: handleYjCode }];

  const DATAGRID_COLUMNS: GridColDef[] = [
    {
      field: 'Edit',
      width: 100,
      headerAlign: 'center',
      headerName: 'Edit',
      align: 'center',
      renderCell: (params: GridRenderCellParams<DataTableRow>) => {
        return (
          <Button
            variant='contained'
            className={classes.Button}
            onClick={() => {
              openEditDialog(params.row);
            }}>
            <Typography>Edit</Typography>
          </Button>
        );
      }
    },
    {
      field: 'yj_code',
      headerName: 'YJ Code',
      width: 250,
      headerAlign: 'center',
      align: 'center'
    }, {
      field: 'trade_name',
      headerName: 'Trade name',
      width: 250,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'formulation',
      headerName: 'Formulation',
      width: 250,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'route',
      headerName: 'Route',
      width: 250,
      headerAlign: 'center',
      align: 'center'
    }
  ];

  const handleSubmit = async () => {
    try {
      const user = await Auth.currentUserInfo();
      const result = await updateEssentialDataCuration({ ...rowData,'curator_name': user.attributes['custom:user'], 'curator_email': user.attributes.email});
      if (result.data) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, color: 'success', message: 'Data saved successfully' }
        });
      } else {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: 'Error: Data not saved' }
        });
      }
      closeEditDialog();
    } catch (error) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: (error as Error).message }
      });
    }
  };

  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <Box display='flex' ml={2} mt={10} alignItems='center'>
        {searchFields.map(row => (
          <TextField
            key={row.id}
            variant='outlined'
            type='search'
            id={row.id}
            label={row.label}
            value={row.value}
            sx={{ paddingRight: '30px' }}
            InputProps={{
              style: { fontSize: '1.5vh', minWidth: '250px', paddingRight: '20px' }
            }}
            InputLabelProps={{
              style: { fontSize: '1.5vh' }
            }}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setPageNo(0);
              row.onChange(event.target.value);
            }}
          />
        ))}
      </Box>
      <Box
        height='80vh'
        p={2}
        mt={1}
        display='flex'
        alignItems='center'
        justifyContent='center'
        className={classes.root}>
        <DataGrid
          sx={styles.datagrid}
          style={{ height: '100%' }}
          rowSpacingType='margin'
          sortingOrder={['desc', 'asc']}
          checkboxSelection={false}
          density='standard'
          rows={dataTableRows}
          rowCount={totalRowCount}
          columns={DATAGRID_COLUMNS}
          loading={isLoading}
          getRowId={row => row.yj_code}
          paginationMode='server'
          getRowClassName={() => `table-rows`}
          pageSize={pageSize}
          page={pageNo}
          onPageSizeChange={newPageSize => {
            setPageSize(newPageSize);
            setPageNo(0);
          }}
          onPageChange={setPageNo}
          rowsPerPageOptions={rowsPerPageOptions}
          components={{
            LoadingOverlay: LinearProgress,
            Footer: DatagridFooter,
            NoRowsOverlay: DatagridNoRowsOverlay
          }}
          pagination
        />
        <Dialog
          open={showEditDialog}
          onClose={closeEditDialog}
          sx={{ backdropFilter: 'blur(5px)' }}
          maxWidth='lg'>
          <ProductData
            productData={rowData}
            setProductData={setRowData}
            handleSubmit={handleSubmit}
            hideSubProductFields={true}
          />
        </Dialog>
      </Box>
    </ThemeProvider>
  );
};

export default EssentialDataCuration;
