import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';

// mui
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Close } from '@mui/icons-material';

// api
import { getAdcommMinutes, deleteMinute, submitOverallQcStatus } from '../../api/AdcommModule';

// store
import Store from '../../store';
import GlobalActions from '../../store/actions';

// styles
import themev5 from '../../themev5';
import styles from './styles/minutes';
import PDFViewer from '../AdComm/PDFViewer';
import MinutesCurationForm from './MinutesCurationForm';
import { qcStatus } from './constant';

const AdcommMinutes = () => {
  const { dispatch } = useContext<any>(Store);
  const { meetingId } = useParams<any>();
  const history = useHistory();
  const location = useLocation();
  const updatedSearchParams = React.useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const [loading, setLoading] = useState<boolean>(true);
  const [minuteData, setMinuteData] = useState<Array<any>>([]);
  const [pdfData, setpdfData] = useState<Array<any>>([]);
  const [viewPdf, setViewPdf] = useState<boolean>(true);
  const [deleteData, setDeleteData] = useState<any>();
  const [value, setValue] = useState<string>('');
  const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
  const [minutesStatus, setMinutesStatus] = useState('Non Qced');
  const [openConfimationDialog, setOpenConfimationDialog] = useState<boolean>(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await getAdcommMinutes(meetingId);
      if (result.data.status === 200) {
        setMinuteData(result.data.body.minutes || []);
        setpdfData(result.data.body.pdf || []);
        if ('qc_status' in result.data.body.qc_info) {
          setMinutesStatus(
            result.data.body.qc_info.qc_status === 'to_qc'
              ? 'Non Qced'
              : result.data.body.qc_info.qc_status
          );
        }
      }
    } catch (error) {
      await dispatch({
        type: GlobalActions.SET_ALERT,
        value: { status: true, message: `${error}` }
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [meetingId]);

  const handleGoBack = useCallback(() => {
    history.push(`/adcomm/?${updatedSearchParams.toString()}`);
  }, [history]);

  const sortQuestion = useCallback((a: any, b: any) => {
    return a.question_num.localeCompare(b.question_num);
  }, []);

  const handleDeleteMinutes = async (id: string) => {
    try {
      const result = await deleteMinute(id);
      if (result.data.status === 200) {
        await dispatch({
          type: GlobalActions.SET_ALERT,
          value: { status: true, message: `Deleted Successfully`, color: 'success' }
        });
      } else {
        await dispatch({
          type: GlobalActions.SET_ALERT,
          value: { status: true, message: `Failed to delete` }
        });
      }
    } catch (e) {
      await dispatch({
        type: GlobalActions.SET_ALERT,
        value: { status: true, message: `Failed to delete` }
      });
    }
    setDeleteData(null);
    fetchData();
  };

  const closeEditDialog = useCallback(() => {
    setShowEditDialog(false);
    fetchData();
  }, []);

  const openEditDialog = useCallback(() => {
    setShowEditDialog(true);
  }, []);

  const handleSubmitQcStatus = useCallback(async () => {
    try {
      if (minutesStatus) {
        const user = await Auth.currentUserInfo();
        const payload = {
          qc_status: minutesStatus === 'Non Qced' ? 'to_qc' : minutesStatus.toLowerCase(),
          curator_email: user.attributes.email,
          curator_name: user.attributes['custom:user']
        };
        const response = await submitOverallQcStatus(meetingId, 'minutes', payload);
        if (response.data.status === 200) {
          await dispatch({
            type: GlobalActions.SET_ALERT,
            value: { status: true, message: `Updated Successfully`, color: 'success' }
          });
        } else {
          await dispatch({
            type: GlobalActions.SET_ALERT,
            value: { status: true, message: `Failed to Update` }
          });
        }
      }
    } catch (error: any) {
      await dispatch({
        type: GlobalActions.SET_ALERT,
        value: { status: true, message: error.message }
      });
    } finally {
      setOpenConfimationDialog(false);
    }
  }, [minutesStatus]);

  if (loading) {
    return (
      <Box sx={{ minHeight: '100vh' }}>
        <Container maxWidth='lg' sx={{ pt: 10 }}>
          <Box
            sx={{
              width: '100%',
              height: '70vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <LinearProgress
              sx={{ width: '100%', maxWidth: '400px', marginBottom: '1em' }}
              color='secondary'
            />
            <Typography sx={{ fontSize: '25px', color: '#3AB09E' }}>Loading data ....</Typography>
          </Box>
        </Container>
      </Box>
    );
  }
  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <Box sx={{ minHeight: '100vh' }}>
        <Container maxWidth='lg' sx={{ pt: 10 }}>
          <Box
            display='flex'
            padding={1}
            flexDirection='column'
            justifyContent='space-between'
            flexWrap='wrap'>
            <Stack flexDirection='row' justifyContent='space-between'>
              <Stack flexDirection='row'>
                <IconButton aria-label='go-back' onClick={handleGoBack}>
                  <ArrowBackIcon />
                </IconButton>
                <Button
                  variant='contained'
                  sx={styles.Button}
                  onClick={() => {
                    setValue('');
                    openEditDialog();
                  }}>
                  <Typography sx={{ color: 'white.main' }}>Add Minutes</Typography>
                </Button>
              </Stack>

              <Button
                sx={styles.Button}
                disabled={pdfData?.length === 0}
                onClick={() => setViewPdf(prevState => !prevState)}
                variant='contained'>
                {viewPdf ? 'Hide' : 'View'} PDF
              </Button>
            </Stack>

            <Stack flexDirection='row' alignItems='center'>
              <Typography variant='h5' sx={styles.title}>
                Meeting id - {meetingId}{' '}
                {minuteData.length > 0 && ` - (${minuteData.length} meeting minutes)`}
              </Typography>
              <Autocomplete
                id='miuntes_qc_status'
                value={minutesStatus}
                onChange={(event: React.ChangeEvent<{}>, newValue: string | null): void => {
                  if (newValue !== null) {
                    setMinutesStatus(newValue);
                    setOpenConfimationDialog(true);
                  }
                }}
                style={{ fontSize: '1.5vh', minWidth: '250px', paddingRight: '20px' }}
                options={qcStatus}
                renderInput={(params: any) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    placeholder='Minutes QC Status'
                    label='MInutes QC Status'
                    variant='outlined'
                  />
                )}
              />
            </Stack>
          </Box>
          <Box display='flex' flexDirection='row'>
            {minuteData.length !== 0 ? (
              <List
                sx={{ width: '100%', maxHeight: '75vh', overflow: 'auto', paddingRight: '2em' }}>
                {minuteData
                  ?.sort(sortQuestion)
                  .map(({ id, question, question_num: qsNo }, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <ListItem key={index} sx={styles.list} disablePadding>
                      <ListItemButton
                        onClick={() => {
                          setValue(id);
                          openEditDialog();
                        }}>
                        <ListItemText
                          secondary={
                            <Typography sx={styles.secondaryText} variant='caption'>
                              {id}
                            </Typography>
                          }
                          primary={`${qsNo}: ${question}`}
                          color='white'
                        />
                        <Button
                          sx={styles.Button}
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            setDeleteData({ id, question, qsNo });
                          }}>
                          Delete
                        </Button>
                      </ListItemButton>
                    </ListItem>
                  ))}
              </List>
            ) : (
              <Box
                display='flex'
                flexDirection='row'
                sx={{
                  width: '100%',
                  height: '75vh',
                  overflow: 'auto',
                  paddingRight: '2em',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                <Typography sx={{ fontSize: '20px' }}>Minutes Not Avaliable</Typography>
              </Box>
            )}
            {viewPdf && pdfData && (
              <Box display='flex' sx={styles.pdfContainer}>
                <PDFViewer pdf={pdfData} setSelectedS3Link={() => {}} />
              </Box>
            )}
          </Box>
          <Dialog fullWidth maxWidth='xs' open={!!deleteData} onClose={() => setDeleteData(null)}>
            <DialogTitle id='alert-dialog-title'>
              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Typography style={{ color: '#3AB09E', fontSize: '20px', fontWeight: 'bold' }}>
                  Confirm Delete
                </Typography>
                <IconButton aria-label='close' onClick={() => setDeleteData(null)}>
                  <Close />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent>
              <Typography style={{ fontSize: '16px', color: 'GrayText', marginRight: '0' }}>
                Are you sure you have edited the document accurately? -{' '}
                {`${deleteData?.qsNo} - ${deleteData?.question}`}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                variant='contained'
                onClick={() => handleDeleteMinutes(deleteData?.id)}
                sx={{ background: '#3AB09E', marginRight: '8px', color: 'whitesmoke' }}>
                Yes
              </Button>
              <Button
                variant='contained'
                onClick={() => setDeleteData(null)}
                sx={{ background: '#3AB09E', marginRight: '8px', color: 'whitesmoke' }}>
                No
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={showEditDialog}
            sx={{ backdropFilter: 'blur(5px)' }}
            maxWidth='lg'
            fullWidth>
            <MinutesCurationForm closeEdit={closeEditDialog} id={value || ''} pdf={pdfData || []} />
          </Dialog>
        </Container>
      </Box>
      <Dialog fullWidth maxWidth='xs' open={openConfimationDialog}>
        <DialogTitle id='alert-dialog-title'>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography style={{ color: '#3AB09E', fontSize: '20px', fontWeight: 'bold' }}>
              Confirm submission
            </Typography>
            <IconButton aria-label='close' onClick={() => setOpenConfimationDialog(false)}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography style={{ fontSize: '16px', color: 'GrayText', marginRight: '0' }}>
            {`Are you sure you want update the qced status as ${minutesStatus} ?`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            onClick={() => {
              handleSubmitQcStatus();
            }}
            sx={{ background: '#3AB09E', marginRight: '8px', color: 'whitesmoke' }}>
            Yes
          </Button>
          <Button
            variant='contained'
            onClick={() => setOpenConfimationDialog(false)}
            sx={{ background: '#3AB09E', marginRight: '8px', color: 'whitesmoke' }}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default React.memo(AdcommMinutes);
