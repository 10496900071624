import React, { useState, useEffect, useContext, SyntheticEvent, ChangeEvent } from 'react';
import { Auth } from 'aws-amplify';

// material
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { FormControlLabel, MenuItem, Switch, DialogContent } from '@mui/material';
// Store
import Actions from '../../store/actions';
import Store from '../../store';

// api
import { fetchLabelPageCurationDetails, submitLabelPageCuration } from '../../api/Mhra';

// constant
import { CurationStatus, Fields } from './contants';

// utils
import getsortLabels from '../../utils/getSortLabels';
import TextFieldCard from '../TextFieldCard';

// styles
import styles from './styles/pageCurationDialog';

const PageEditDialog = ({ closeEdit, productData, original }: any) => {
  type MonographData = {
    name_of_the_medicinal_product: number | string;
    qualitative_and_quantitative_composition: number | string;
    pharmaceutical_form: number | string;
    therapeutic_indications: number | string;
    posology_and_method_of_administration: number | string;
    contraindications: number | string;
    special_warnings_and_precautions_for_use: number | string;
    interaction_with_other_medicinal_products_and_other_forms_of_in: number | string;
    fertility_pregnancy_and_lactation: number | string;
    effects_on_ability_to_drive_and_use_machines: number | string;
    undesirable_effects: number | string;
    overdose: number | string;
    pharmacodynamic_properties: number | string;
    pharmacokinetic_properties: number | string;
    preclinical_safety_data: number | string;
    pharmaceutical_particulars: number | string;
    marketing_authorisation_holder: number | string;
    marketing_authorisation_number: number | string;
    date_of_first_authorisation_renewal_of_the_authorisation: number | string;
    date_of_revision_of_the_text: number | string;
    annex_ii: number | string;
    annex_iii: number | string;
    dosimetry: number | string;
    instructions_for_preparation_of_radiopharmaceuticals: number | string;
    qc_status: string;
    disabled_sections: Array<any>;
    [key: string]: number | string | Array<any>;
  };
  const { dispatch } = useContext<any>(Store);
  const [pdfUrl, setpdfUrl] = useState('');
  const [autoStart, setAutoStart] = useState(true);
  const [monographData, setMonographData] = useState<MonographData>({
    name_of_the_medicinal_product: 0,
    qualitative_and_quantitative_composition: 0,
    pharmaceutical_form: 0,
    therapeutic_indications: 0,
    posology_and_method_of_administration: 0,
    contraindications: 0,
    special_warnings_and_precautions_for_use: 0,
    interaction_with_other_medicinal_products_and_other_forms_of_in: 0,
    fertility_pregnancy_and_lactation: 0,
    effects_on_ability_to_drive_and_use_machines: 0,
    undesirable_effects: 0,
    overdose: 0,
    pharmacodynamic_properties: 0,
    pharmacokinetic_properties: 0,
    preclinical_safety_data: 0,
    pharmaceutical_particulars: 0,
    marketing_authorisation_holder: 0,
    marketing_authorisation_number: 0,
    date_of_first_authorisation_renewal_of_the_authorisation: 0,
    date_of_revision_of_the_text: 0,
    annex_ii: 0,
    annex_iii: 0,
    dosimetry: 0,
    instructions_for_preparation_of_radiopharmaceuticals: 0,
    qc_status: 'to_qc',
    document_hash: '',
    disabled_sections: []
  });
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const handleSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      const user = await Auth.currentUserInfo();
      const payload = {
        name_of_the_medicinal_product: monographData.name_of_the_medicinal_product ?? 0,
        qualitative_and_quantitative_composition:
          monographData.qualitative_and_quantitative_composition ?? 0,
        pharmaceutical_form: monographData.pharmaceutical_form ?? 0,
        therapeutic_indications: monographData.therapeutic_indications ?? 0,
        posology_and_method_of_administration:
          monographData.posology_and_method_of_administration ?? 0,
        contraindications: monographData.contraindications ?? 0,
        special_warnings_and_precautions_for_use:
          monographData.special_warnings_and_precautions_for_use ?? 0,
        interaction_with_other_medicinal_products_and_other_forms_of_in:
          monographData.interaction_with_other_medicinal_products_and_other_forms_of_in ?? 0,
        fertility_pregnancy_and_lactation: monographData.fertility_pregnancy_and_lactation ?? 0,
        effects_on_ability_to_drive_and_use_machines:
          monographData.effects_on_ability_to_drive_and_use_machines ?? 0,
        undesirable_effects: monographData.undesirable_effects ?? 0,
        overdose: monographData.overdose ?? 0,
        pharmacodynamic_properties: monographData.pharmacodynamic_properties ?? 0,
        pharmacokinetic_properties: monographData.pharmacokinetic_properties ?? 0,
        preclinical_safety_data: monographData.preclinical_safety_data ?? 0,
        pharmaceutical_particulars: monographData.pharmaceutical_particulars ?? 0,
        marketing_authorisation_holder: monographData.marketing_authorisation_holder ?? 0,
        marketing_authorisation_number: monographData.marketing_authorisation_number ?? 0,
        date_of_first_authorisation_renewal_of_the_authorisation:
          monographData.date_of_first_authorisation_renewal_of_the_authorisation ?? 0,
        date_of_revision_of_the_text: monographData.date_of_revision_of_the_text ?? 0,
        annex_ii: monographData.annex_ii ?? 0,
        annex_iii: monographData.annex_iii ?? 0,
        dosimetry: monographData.dosimetry ?? 0,
        instructions_for_preparation_of_radiopharmaceuticals:
          monographData.instructions_for_preparation_of_radiopharmaceuticals ?? 0,
        qc_status: monographData.qc_status,
        pdf_metadata_id: productData.pdf_metadata_id,
        document_hash: productData.document_hash,
        product_number: productData.product_number,
        curator_email: user.attributes.email,
        curator_name: user.attributes['custom:user'],
        section_separated: original
      };
      const res = await submitLabelPageCuration(payload);
      const resData = res.data;
      if (resData.error) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: resData.message }
        });
      } else {
        closeEdit();
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: 'Page Data Updated Successfully', color: 'success' }
        });
      }
    } catch (e: any) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: e.message }
      });
    }
  };

  useEffect(() => {
    const getProductData = async () => {
      try {
        const result = await fetchLabelPageCurationDetails(productData.pdf_metadata_id, original);
        if (!result?.data?.body || result.status !== 200) {
          throw Error('Result Not Available');
        } else if (result.data.body) {
          const resultData = result?.data?.body?.results[0] ?? {};
          setMonographData({
            name_of_the_medicinal_product: resultData.name_of_the_medicinal_product ?? 0,
            qualitative_and_quantitative_composition:
              resultData.qualitative_and_quantitative_composition ?? 0,
            pharmaceutical_form: resultData.pharmaceutical_form ?? 0,
            therapeutic_indications: resultData.therapeutic_indications ?? 0,
            posology_and_method_of_administration:
              resultData.posology_and_method_of_administration ?? 0,
            contraindications: resultData.contraindications ?? 0,
            special_warnings_and_precautions_for_use:
              resultData.special_warnings_and_precautions_for_use ?? 0,
            interaction_with_other_medicinal_products_and_other_forms_of_in:
              resultData.interaction_with_other_medicinal_products_and_other_forms_of_in ?? 0,
            fertility_pregnancy_and_lactation: resultData.fertility_pregnancy_and_lactation ?? 0,
            effects_on_ability_to_drive_and_use_machines:
              resultData.effects_on_ability_to_drive_and_use_machines ?? 0,
            undesirable_effects: resultData.undesirable_effects ?? 0,
            overdose: resultData.overdose ?? 0,
            pharmacodynamic_properties: resultData.pharmacodynamic_properties ?? 0,
            pharmacokinetic_properties: resultData.pharmacokinetic_properties ?? 0,
            preclinical_safety_data: resultData.preclinical_safety_data ?? 0,
            pharmaceutical_particulars: resultData.pharmaceutical_particulars ?? 0,
            marketing_authorisation_holder: resultData.marketing_authorisation_holder ?? 0,
            marketing_authorisation_number: resultData.marketing_authorisation_number ?? 0,
            date_of_first_authorisation_renewal_of_the_authorisation:
              resultData.date_of_first_authorisation_renewal_of_the_authorisation ?? 0,
            date_of_revision_of_the_text: resultData.date_of_revision_of_the_text ?? 0,
            annex_ii: resultData.annex_ii ?? 0,
            annex_iii: resultData.annex_iii ?? 0,
            dosimetry: resultData.dosimetry ?? 0,
            instructions_for_preparation_of_radiopharmaceuticals:
              resultData.instructions_for_preparation_of_radiopharmaceuticals ?? 0,
            qc_status: resultData.qc_status || 'to_qc',
            document_hash: productData.document_hash || '',
            pdf_metadata_id: productData.pdf_metadata_id || '',
            disabled_sections: resultData.disabled_sections ?? []
          });
          setpdfUrl(
            original
              ? resultData?.section_separated_document_link
              : resultData?.original_document_link
          );
        }
      } catch (e: any) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: e.message }
        });
      }
    };
    getProductData();
  }, []);

  return (
    <Container sx={{ overflow: 'hidden' }}>
      <div>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography component='h2' variant='h5' style={styles.heading}>
            Label Page Number Curation
          </Typography>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={autoStart}
                  onChange={() => {
                    setAutoStart(!autoStart);
                  }}
                />
              }
              label='Auto sorting'
            />
          </Grid>
          <IconButton
            aria-label='close'
            onClick={() => {
              closeEdit();
            }}
            sx={styles.closeButton}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} display='flex'>
            <Grid item xs={12} lg={6} mb={3} sx={styles.gridContainer}>
              <form onSubmit={handleSubmit} style={{ marginTop: '20px' }}>
                <Grid container spacing={3}>
                  {autoStart ? (
                    <Grid item xs={12}>
                      {getsortLabels(Fields, monographData).map((arg: any) => {
                        return (
                          <TextFieldCard
                            key={arg.id}
                            id={arg.id}
                            labelName={arg.labelName}
                            data={monographData}
                            setData={setMonographData}
                            setDisableSubmit={setDisableSubmit}
                          />
                        );
                      })}
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      {Fields.map((arg: any) => {
                        return (
                          <TextFieldCard
                            key={arg.id}
                            id={arg.id}
                            labelName={arg.labelName}
                            data={monographData}
                            setData={setMonographData}
                            setDisableSubmit={setDisableSubmit}
                          />
                        );
                      })}
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <TextField
                      variant='outlined'
                      id='status'
                      label='QC Status'
                      select
                      value={monographData.qc_status}
                      onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                        if (event.target.value !== null) {
                          setMonographData(prevData => {
                            return { ...prevData, qc_status: event.target.value };
                          });
                        }
                      }}
                      InputProps={{
                        style: { fontSize: '1.5vh', minWidth: '250px' }
                      }}
                      InputLabelProps={{
                        style: { fontSize: '1.5vh' }
                      }}>
                      {Object.entries(CurationStatus).map(([key, value]: any) => (
                        <MenuItem value={key}>{value}</MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} display='flex' direction='column'>
                    <Button
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='primary'
                      disabled={disableSubmit}
                      style={{ color: 'white' }}>
                      SUBMIT
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <Grid item xs={12} lg={6} sx={{ height: '70vh' }}>
              <iframe
                src={pdfUrl}
                title='Product Monograph'
                style={{ height: '100%', width: '100%' }}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </div>
    </Container>
  );
};

export default React.memo(PageEditDialog);
