import { memo, useEffect, useContext, useState, ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import { debounce } from 'lodash';
import {
  ThemeProvider,
  CssBaseline,
  Box,
  TextField,
  Button,
  Typography,
  LinearProgress,
  Autocomplete,
  Dialog
} from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import DatagridFooter from '../Datagrid/DatagridFooter';
import DatagridNoRowsOverlay from '../Datagrid/DatagridNoRowsOverlay';
import themev5 from '../../themev5';
import { getPmdaProductResources } from '../../api/pmda';

import ProductResourcePageEditDialog from './ProductResourcePageEditDialog';
import ProductResourceSectionDialog from './ProductResourceSectionDialog';

import { DataTableRow } from './types';
import { qcStatuses } from './utils';

// Styles
import Styles from './styles/ProductResourcesCuration.styles';
import styles from '../Datagrid/Datagrid.styles';

// Store
import Store from '../../store';
import Actions from '../../store/actions';

const qcOptions = ['All Documents', ...qcStatuses];

const ProductResourcesCuration = () => {
  const { curation_type: curationType } = useParams() as any;
  const classes = Styles();
  const { dispatch } = useContext<any>(Store);
  const [dataTableRows, setDataTableRows] = useState<Array<DataTableRow>>([]);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [yjCode, setYjCode] = useState('');
  const [packageNumber, setPackageNumber] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [pageNo, setPageNo] = useState(0);
  const [reload, setReload] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [qcStatus, setQcStatus] = useState('All Documents');
  const [rowData, setRowData] = useState<DataTableRow>();
  const [showEditDialog, setShowEditDialog] = useState(false);
  const rowsPerPageOptions = [10, 20, 50, 100];
  if (curationType === 'translate' || curationType === 'curation' || curationType === 'essential-data-curation') {
    return <></>
  }
  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const result = await getPmdaProductResources(
          pageSize,
          pageNo,
          qcStatus,
          yjCode,
          packageNumber
        );

        setIsLoading(false);
        if (!result?.data?.body?.result) {
          dispatch({
            type: Actions.SET_ALERT,
            value: { status: true, message: 'Error: Product resources not available' },
          });
        }

        setDataTableRows(result.data.body.result);
        setTotalRowCount(result.data.body.total);
      } catch (error) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: (error as Error).message }
        });
      }
    };

    getData();
  }, [pageNo, pageSize, reload]);

  const debouncedChangeHandler = () => {
    setReload(prevState => !prevState);
  };

  const handleChange = debounce(debouncedChangeHandler, 300);

  const handleYjCode = (value: string) => {
    setYjCode(value);
    handleChange();
  };

  const handlePackageNumber = (value: string) => {
    setPackageNumber(value);
    handleChange();
  };

  const openEditDialog = (data: DataTableRow) => {
    setRowData(data);
    setShowEditDialog(true);
  };
  const closeEditDialog = () => {
    setShowEditDialog(false);
    setReload(prevState => !prevState);
  };

  const searchFields = [
    { id: 'yj_code', label: 'YJ Code', value: yjCode, onChange: handleYjCode },
    {
      id: 'package_number',
      label: 'Package Number',
      value: packageNumber,
      onChange: handlePackageNumber
    }
  ];

  const DATAGRID_COLUMNS: GridColDef[] = [
    {
      field: 'Edit',
      width: 100,
      headerAlign: 'center',
      headerName: 'Edit',
      align: 'center',
      renderCell: (params: GridRenderCellParams<DataTableRow>) => {
        return (
          <Button
            variant='contained'
            className={classes.Button}
            onClick={() => {
              openEditDialog(params.row);
            }}>
            <Typography>Edit</Typography>
          </Button>
        );
      }
    },
    {
      field: 'yj_code',
      headerName: 'YJ Code',
      width: 250,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'package_number',
      headerName: 'Package Number',
      width: 350,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'qc_status',
      headerName: 'Qced Status',
      width: 350,
      headerAlign: 'center',
      align: 'center',
      cellClassName: () => classes.capitalize
    }
  ];

  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <Box display='flex' ml={2} mt={10} alignItems='center'>
        <Autocomplete
          id='qc_status'
          value={qcStatus}
          onChange={(_, newValue: string | null): void => {
            if (newValue !== null) {
              setQcStatus(newValue);
              setPageNo(0);
              handleChange();
            }
          }}
          style={{ fontSize: '1.5vh', minWidth: '250px', paddingRight: '20px' }}
          options={qcOptions}
          renderInput={params => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <TextField {...params} placeholder='QC Status' label='QC Status' variant='outlined' />
          )}
        />
        {searchFields.map(row => (
          <TextField
            key={row.id}
            variant='outlined'
            type='search'
            id={row.id}
            label={row.label}
            value={row.value}
            sx={{ paddingRight: '30px' }}
            InputProps={{
              style: { fontSize: '1.5vh', minWidth: '250px', paddingRight: '20px' }
            }}
            InputLabelProps={{
              style: { fontSize: '1.5vh' }
            }}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setPageNo(0);
              row.onChange(event.target.value);
            }}
          />
        ))}
      </Box>
      <Box
        height='80vh'
        p={2}
        mt={1}
        display='flex'
        alignItems='center'
        justifyContent='center'
        className={classes.root}>
        <DataGrid
          sx={styles.datagrid}
          style={{ height: '100%' }}
          rowSpacingType='margin'
          sortingOrder={['desc', 'asc']}
          checkboxSelection={false}
          density='standard'
          rows={dataTableRows}
          rowCount={totalRowCount}
          columns={DATAGRID_COLUMNS}
          loading={isLoading}
          getRowId={row => row.id}
          paginationMode='server'
          getRowClassName={() => `table-rows`}
          pageSize={pageSize}
          page={pageNo}
          onPageSizeChange={newPageSize => {
            setPageSize(newPageSize);
            setPageNo(0);
          }}
          onPageChange={setPageNo}
          rowsPerPageOptions={rowsPerPageOptions}
          components={{
            LoadingOverlay: LinearProgress,
            Footer: DatagridFooter,
            NoRowsOverlay: DatagridNoRowsOverlay
          }}
          pagination
        />
        <Dialog
          open={showEditDialog}
          onClose={closeEditDialog}
          sx={{ backdropFilter: 'blur(5px)' }}
          maxWidth='lg'>
          {curationType === 'page_number_curation' ? (
            <ProductResourcePageEditDialog closeEdit={closeEditDialog} productResource={rowData} />
          ) : (
            <ProductResourceSectionDialog closeEdit={closeEditDialog} productResource={rowData} />
          )}
        </Dialog>
      </Box>
    </ThemeProvider>
  );
};

export default memo(ProductResourcesCuration);
